<template>
  <section>
    <CIInput
      :label="label.name + '*'"
      :tooltip="tooltip.name"
      v-model="name"
      :placeholder="placeholder.name"
      :disabled="disabled.name"
      name="name"
    />
    <CIInput
      v-if="showKey"
      :label="label.key  + '*'"
      :tooltip="tooltip.key"
      v-model="key"
      :placeholder="placeholder.key"
      :disabled="isDisabled"
      name="key"
      @blur="inputKeyBlurHandler"
    />
  </section>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
    label: {
      type: Object,
      default() {
        return {
          name: 'Name',
          key: 'Key',
        };
      },
    },
    tooltip: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Object,
      default() {
        return {
          name: '',
          key: '',
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showKey: {
      type: Boolean,
      default: false,
    },
    setIsManualKey: {
      type: Function
    },
    isManualKey: {
      type: Boolean,
      default: false,
    },
    setIsEditedKey: {
      type: Function
    },
    isEditedKey: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      name: null,
      key: null,
      isDisabled: false
    };
  },
  created() {
    if (this.value && this.value.name) {
      this.name = this.value.name;
      this.key = this.value.key;
      this.isDisabled = true
    }
    if (this.isEditedKey) this.isDisabled = false
    this.$emit('input', { name: this.name, key: this.key });
    this.$emit('input', { name: this.name, key: this.key });
  },
  watch: {
    name() {
      if (!this.isManualKey && !this.isDisabled) {
        this.key = this.generateKey(this.name);
      }
      this.$emit('input', { name: this.name, key: this.key });
    },
    key() {
      if (!this.isDisabled) this.key = this.generateKey(this.key);
      this.$emit('input', { name: this.name, key: this.key });
      this.$emit('setIsEditedKey');
    },
  },
  methods: {
    randomString(length = 6) {
      return Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
      )
        .toString(36)
        .slice(1);
    },
    inputKeyBlurHandler() {
      this.$emit('setIsManualKey')
    },
    generateKey(value) {
      return value
              .replace(/[^a-z0-9_]+/gi, '-')
              .replace(/^-|-$/g, '');
    },
  },
};
</script>
